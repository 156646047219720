<template>
  <form
    action=""
    method="POST"
    @submit.prevent="onSubmit"
    @keydown="form.errors.clear($event.target.name)"
    enctype="multipart/form-data"
    class="max-w-3xl mx-auto"
  >
    <v-card class="mb-2">
      <v-card-body class="mb-2">
        <v-card-title class="mb-2">Masina</v-card-title>
        
        <v-label for="make_id"> Marca </v-label>
        <select-make
          v-model="form.make_id"
          selectId="make_id"
          class="mb-2 w-full"
          required
        />
        <v-error :error="form.errors.get('make_id')"></v-error>

      </v-card-body>
      <v-card-footer class="text-right">
        <v-button :disabled="form.loading">
          <v-button-icon v-if="form.loading">
            <loader :radius="5" />
          </v-button-icon>
          Salveaza
        </v-button>
      </v-card-footer>
    </v-card>
  </form>
</template>

<script>
import vendorCarsApi from "@/api/resources/vendorCarsApi";
import Form from "@/core/Form";
import VButton from "@/components/VButton.vue";
import VCard from "@/components/VCard.vue";
import VLabel from "@/components/VLabel.vue";
import VError from "@/components/VError.vue";
import SelectMake from "@/components/shared/SelectMake.vue";
import VCardBody from "@/components/VCardBody.vue";
import VCardFooter from "@/components/VCardFooter.vue";
import VCardTitle from "@/components/VCardTitle.vue";
import VButtonIcon from "./VButtonIcon.vue";
import Loader from "./Loader.vue";

export default {
  components: {
    VCard,
    VLabel,
    VButton,
    VError,
    SelectMake,
    VCardBody,
    VCardFooter,
    VCardTitle,
    VButtonIcon,
    Loader,
  },

  props: {
    car: Object,
  },

  emits: ["created:car"],

  data() {
    return {
      form: new Form({
        make_id: "",
      }),
    };
  },

  methods: {
    onSubmit() {
      this.form.submitService(vendorCarsApi, "store").then((data) => {
        this.form.setData(data);
        this.$emit("created:car");
      });
    },
  },

  mounted() {
    if (this.car) {
      this.form.setData(this.car);
    }
  },
};
</script>

<style></style>
